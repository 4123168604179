// App.js
import React from 'react';
import './App.css';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import logo from './assets/TB.png'


function App() {
  // Dummy image URLs (replace with actual image URLs)
  const imageUrls = [
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    'https://via.placeholder.com/300',
    // Add more image URLs as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };

  return (
    <div className="app-container">
   <header className="header">
  <div className="logo-container">
    <a href='#home'><img src={logo} alt="Logo" className="logo" height={150} /></a> 
  </div>
  <nav className="nav">
    <ul>
      <li><a href="#home">Home</a></li>
      <li><a href="#about">About</a></li>
      <li><a href="#services">Services</a></li>
      <li><a href="#gallery">Gallery</a></li>
      <li><a href="#contact">Contact</a></li>
    </ul>
  </nav>
</header>


      <main className="main-content">
        <section id="home" className="section">
          <h2>Welcome to Tera Boepple Photography</h2>
          <p></p>
        </section>
        <section id="about" className="section">
          <h2>Meet Tera</h2>
          <p>
            Hi there! I'm Tera Boepple, the creative eye behind the lens. Photography has been my passion for as long as I can remember. What started as a hobby quickly evolved into a full-fledged career, driven by my love for capturing life's most precious moments.
          </p>
          <p>
            I believe in the power of storytelling through images. Every photograph we take tells a unique story, encapsulating emotions, personalities, and the essence of the moment. I strive to create images that not only look beautiful but also capture genuine emotions and memories that will last a lifetime. I'm excited to embark on this photographic journey with you and capture moments that you'll cherish forever. Whether you're planning a wedding, a family photoshoot, or simply want to document a special milestone, I'd love to chat and discuss how we can bring your vision to life. Let's create something beautiful together!
          </p>
          <p>
            I specialize in portrait, engagement & wedding, family photography, maternity, newborn, and much more. I offer a range of photography services tailored to meet your unique needs. Whether it's capturing the joy of a wedding day, the warmth of family gatherings, or the individuality of a portrait session, I approach each project with creativity, professionalism, and a personal touch.
          </p>
          <p>
            My approach to photography is rooted in authenticity and connection. I believe in building genuine relationships with our clients, getting to know their stories, and understanding their vision for their photoshoot. This allows us to create a comfortable and relaxed atmosphere where our clients can be themselves, resulting in natural and candid images that reflect their true essence.
          </p>
        </section>
        <section id="services" className="section">
          <h2>Services</h2>
          <div class="services-section">
  <div class="service">
    <h3>Portrait Photography</h3>
    <p>Capture beautiful portraits for individuals or groups</p>
  </div>
  <div class="service">
    <h3>Wedding Photography</h3>
    <p>Capture the magic and emotions of your wedding day</p>
  </div>
  <div class="service">
    <h3>Family Photography</h3>
    <p>Document special moments with your family</p>
  </div>
  {/* <!-- Add more services as needed --> */}
</div>



        </section>
        <section id="gallery" className="section">
          <h2>Gallery</h2>
          <Slider {...settings}>
            {imageUrls.map((imageUrl, index) => (
              <div key={index}>
                <img src={imageUrl} alt={`Photo ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </section>
        <section id="contact" className="section">
          <h2>Contact Me</h2>
          <p>
            For inquiries or to book a session, please contact me via email <a
              href="mailto:teraboepple@gmail.com" target='blank'>here</a> or
            call me <a href="tel:+7857668749">here</a>.
          </p>        </section>
      </main>
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} Tera Boepple Photography. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
